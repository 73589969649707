@media query {

}

.contact-tile {
  width: 20px;
}

.contact-div > a {
  color: white;
  text-decoration: none;
}

.contact-flex {
  color: white;
  text-decoration: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.contact-icon {
  display: inline-block;
}

.contact-spacer {
  width: .4vw;
}

.contact-spacer-inline {
  width: .4vw;
  display: inline-block;
}

.contact-text {
  display: flex;
  flex-wrap: wrap;
}

.contact-text-inline {
  display: inline-block;
  color: white;
  text-decoration: none;
}

.content {
  width: 70vw;
  min-height: 100vh;
  background-color: lightgrey;
  padding: 2vw;
}

.content-holder {
  margin: 5px;
}

.faded {
  color: yellow;
}

.flare {
  color: yellow;
  /* text-shadow: -1px -2px 2px yellow; */
}

.header {
  padding-left: 2vw;
  background-color: darkgrey;
}

.hide {
  display: none;
}

.icon-div-holder {
  display: inline-block;
}

.links {
  width: 30vw;
  height: 40vw;
  background-color: black;
  color: white;
}

.links-content {
  padding: 5px 2vw;
}

.links-grid {
  display: inline-grid;
  grid-template-areas:
    'icon1 text1'
    'icon2 text2'
    'icon3 text3'
    'icon4 text4';
  grid-column-gap: .4vw;
  grid-row-gap: .4vw;
}

.links-icon1 { grid-area: icon1; }
.links-text1 { grid-area: text1; }
.links-icon2 { grid-area: icon2; }
.links-text2 { grid-area: text2; }
.links-icon3 { grid-area: icon3; }
.links-text3 { grid-area: text3; }
.links-icon4 { grid-area: icon4; }
.links-text4 { grid-area: text4; }


.nav {
  width: 30vw;
  background-color: black;
}

.navlist > a {
  color: white;
  text-decoration: none;
}

.navlist > a > .navitem {
  line-height: 1.5;
  font-size: 3vw;
  font-weight: bold;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.no-style {
  color: inherit;
  text-decoration: none;
}

.proj {
  border-radius: 4px;
  padding: 1vw 3vw 1vw 3vw;
  background-color: white;
}

.projlink {
  display: inline-block;
  margin: 2px;
  border: 1px solid black;
  border-radius: 3px;
  padding: 1vw;
  background-color: grey;
  color: white;
  font-weight: bold;
}

.projlink:hover {
  background-color: black;
  color: yellow;
}

.sidebar {
  /* display: inline-block; */
}

.top {
  display: flex;
}

.trans {
  width: 30vw;
  height: 1vw;
}

.t1 {
  background-color: #969696;
}

.t2 {
  background-color: #8c8c8c;
}

.t3 {
  background-color: #727272;
}

.t4 {
  background-color: #696969;
}

.t5 {
  background-color: #5e5e5e;
}

.t6 {
  background-color: #4e4e4e;
}

.t7 {
  background-color: #3e3e3e;
}

.t8 {
  background-color: #2c2c2c;
}

.t9 {
  background-color: #1e1e1e;
}

.tiny {
  font-size: 10px;
}

.white-link {
  color: white;
  text-decoration: none;
}

a:link > .navitem {
  text-shadow: 1px 1px 2px black;
}

a:hover > .navitem {
  text-shadow: 2px 3px 2px black;
}

a:active > .navitem {
  color: yellow;
  text-shadow: 2px 3px 2px #424200;
}

body {
  background-color: black;
}

h1 {
  margin: 0;
  line-height: 0.75;
  font-size: 5vw;
}

h2 {
  text-align: center;
  font-size: 5vw;
}

h3 {
  font-size: 24px;
}

h4 {
  margin: 0;
  color: white;
  font-size: 2.3vw;
  text-align: center;
}

hr {
  margin: inherit 2vw 0 2vw;
  border: 1px solid yellow;
}

nav {
  padding: 1px;
  background-color: grey;
}

nav ul li {
  text-align: center;
  list-style: none;
}

nav ul li a {
  color: white;
  text-decoration: none;
}

ul {
  margin: 1vw;
  padding: 0;
}

@media (max-width: 699px) {
  .navlist > a > .navitem {
    font-size: 20px;
  }
  h4 {
    font-size: 16.1px;
  }
}

@media (max-width: 499px) {
  .navlist > a > .navitem {
    font-size: 15px;
  }
}

@media (min-width: 700px) {
  h2 {
    font-size: 35px;
  }
}

@media (min-width: 900px) {
  .content {
    width: 100%;
  }

  .nav, .trans, .links {
    width: 270px;
  }

  .navlist > a > .navitem {
    font-size: 27px;
  }
}
@media (min-width: 1000px) {
  .header {
    padding-left: 20px;
  }
  h1 {
    font-size: 50px;
  }
}
